<template>
  <Workspace>
    <template v-slot:top-bar>
      <TopBar>
        <template v-slot:title>Dashboard </template>
      </TopBar>
    </template>
    <template v-slot:content>
      <div class="content flex-grow-1">



      </div>
    </template>
  </Workspace>
</template>

<script>
import Workspace from "../layouts/Workspace";
import TopBar from "../layouts/workspace/TopBar";
import {mapGetters} from "vuex";
import router from "../routes";

export default {
  name: "Dashboard",
  components: { Workspace, TopBar},
  computed: {
    ...mapGetters("auth/user", ["user", "canViewFinalClaimPack"]),
    ...mapGetters('auth/user/claim', ['isClaimSelected', 'isClaimPackMycoApprove']),
    ...mapGetters('messages', ['countUnreadMessages']),
    countUnreadMessagesText: function() {
      if (this.isClaimSelected) {
        return ` (${this.countUnreadMessages})`
      }
      return '';
    }
  },
  methods: {
    handleClickDocument() {
      router.push("documents");
    },
    handleClickMessages() {
      router.push("messages");
    },
    handleClickClaim() {
      router.push("final-claim-pack");
    }
  }
}
</script>

<style scoped>

</style>